module controllers {
    export module master {
        interface ICustomerUpdateScope extends ng.IScope {
        }

        interface ICustomerUpdateScopeParams extends ng.ui.IStateParamsService {
            comId: number;
        }

        export class customerUpdateCtrl {

            static $inject = [
                "$scope",
                '$rootScope',
                'generalService',
                '$q',
                '$anchorScroll',
                '$uibModal',
                'bsLoadingOverlayService',
                '$stateParams',
                'entityService',
                '$state',
                '$timeout',
                '$transitions',
                'documentRepositoryService',
                'customerService',
                'termsOfPaymentService',
                'incotermService',
                'currencyService',
                'termsOfDeliveryService',
                'routeCodeService',
                'costModelService',
                'bankService',
                'variableDocsTemplateService'
            ];


            comId: number;
            customerMaster: interfaces.master.ICustomerMaster;

            breadCrumbDesc: string;

            isLoadingDetail: boolean = false;
            showAll: boolean = false;

            documentRepository: interfaces.documentManagement.IDocumentRepository;
            termsOfDeliveryList: Array<interfaces.applicationcore.IDropdownModel>;

            constructor(
                private $scope: ICustomerUpdateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private bsLoadingOverlayService,
                private $stateParams: ICustomerUpdateScopeParams,
                private entityService: interfaces.applicationcore.IEntityService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                public documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                public customerService: services.master.customerService,
                public termsOfPaymentService: services.master.termsOfPaymentService,
                public incotermService: services.applicationcore.incotermService,
                public currencyService: services.applicationcore.currencyService,
                public termsOfDeliveryService: services.master.termsOfDeliveryService,
                public routeCodeService: services.master.routeCodeService,
                public costModelService: services.costing.costModelService,
                public bankService: services.master.bankService,
                private variableDocsTemplateService: services.master.variableDocsTemplateService,
            ) {

                this.comId = $stateParams.comId;

                var loadPromises: ng.IPromise<any>[] = [];

                loadPromises.push(this.loadCustomer(this.comId));

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'com.update'
                },
                    () => {
                        return $q.all(loadPromises);
                    });
            }

            loadCustomer(Id: number) {
                return this.customerService.getCustomer(Id).query((data: interfaces.master.ICustomerMaster) => {

                    this.customerMaster = data;
                    this.breadCrumbDesc = this.customerMaster.Code + " - " + this.customerMaster.Name;
                    
                    this.loadTermsOfDeliveries();
                    this.getDocuments(this.showAll);

                }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;

            }

            loadTermsOfPayment(searchText: string) {
                return this.termsOfPaymentService.getTermsOfPaymentDropdown(this.customerMaster.EntityId, searchText).query({
                }, (resultList) => {
                }).$promise;
            }

            loadIncoterms() {
                return this.incotermService.getDropdownList().query({
                }, (resultList) => {
                }).$promise;
            }

            incoTermChanged(model: interfaces.applicationcore.IDropdownModel) {
                this.customerMaster.TermsOfDelivery = null;
                if (model) {
                    this.termsOfDeliveryList = [];
                    this.loadTermsOfDeliveries();
                }
            }

            loadTermsOfDeliveries() {
                var defered = this.$q.defer();

                if (!this.customerMaster || !this.customerMaster.Incoterm) {
                    defered.resolve([]);
                    return defered.promise;
                }

                this.termsOfDeliveryService.getDropdownList().query({ ownerEntityId: this.customerMaster.EntityId, incoTermId: this.customerMaster.Incoterm.Id }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(list);
                    this.termsOfDeliveryList = list;
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;
            }

            loadCurrencies() {
                return this.currencyService.getCurrencyDropdownList().query({
                }, (resultList) => {
                }).$promise;
            }

            loadBanks() {
                return this.bankService.getDropdownList(this.customerMaster.EntityId, "").query({
                }, (resultList) => {
                }).$promise; 
            }

            loadRoutes() {
                return this.routeCodeService.getDropdownListAll(this.customerMaster.EntityId, false).query({
                }, (resultList) => {
                }).$promise;
            }

            loadVariableTemplates() {
                return this.variableDocsTemplateService.getDropDownList(this.customerMaster.EntityId).query({
                }, (resultList) => {
                }).$promise;
            }

            //461 = Sales Order
            loadSalesOrderCostModels() {
                return this.costModelService.getDropdownList().query({
                    ownerEntityId: this.customerMaster.EntityId,
                    code: "",
                    name: "",
                    typeId: 461
                }, (resultList) => {
                }).$promise;
            }

            SaveCustomer(): ng.IPromise<boolean> {
                var deferre = this.$q.defer<boolean>();

                this.customerService.save().save(this.customerMaster, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data);

                    this.comId = Number(data.ID);

                    deferre.resolve(true);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferre.resolve(false);
                });

                return deferre.promise;
            };

            Save() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'com.update'
                },
                    () => {
                        return this.SaveCustomer().then((data: boolean) => {
                            if (data) {
                                this.loadCustomer(this.comId);
                                this.$anchorScroll("topAnchor");
                                this.$state.go("auth.MasterData.Customer.Update", { comId: this.comId });
                            }
                        });
                    });

            }

            SaveClose() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'com.update'
                },
                    () => {
                        return this.SaveCustomer().then((data) => {
                            if (data) {
                                this.$state.go("^");
                            } else {
                                this.$anchorScroll("topAnchor");
                            }
                        });
                    });
            }

            Close() {
                this.$state.go("^");
            }

            getDocuments(showAll:boolean) {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'com.update'
                },
                    () => {
                        return this.documentRepositoryService.getDocumentRepository().query({ referenceNumber: this.customerMaster.Code, systemType: Enum.EnumSystemType.Company, ownerEntityId: this.customerMaster.EntityId, showAll:showAll }, (documentRepository: interfaces.documentManagement.IDocumentRepository) => {
                            this.documentRepository = documentRepository;
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }

            refreshDocuments(showAll:boolean) {
                this.showAll = showAll;
                this.documentRepository = null;
                return this.getDocuments(showAll);
            }
        };

        angular.module("app").controller("customerUpdateCtrl", controllers.master.customerUpdateCtrl);
    }
}
